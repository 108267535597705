<template>
  <section>
    <a-page-header :title="pageTitle" :style="{ marginBottom: '0.5rem' }">
      <template #extra>
        <search-expandable size="large" placeholder="Поиск по названию и коду объекта" v-model="formSearch.search"
                           @enter="findReservation"/>
      </template>
    </a-page-header>

    <main>
      <nav class="ant-navbar filter-navbar" :style="{ marginBottom: '3rem' }" :class="{ loading }">

        <a-tabs v-model:activeKey="searchMode" :style="{ marginBottom: '1.5rem' }" @change="onSearchModeChange">
          <a-tab-pane key="reservations" tab="Забронированные слоты"></a-tab-pane>
          <a-tab-pane key="free_slots" tab="Свободные слоты"></a-tab-pane>
        </a-tabs>

        <a-form layout="vertical" :model="formSearch" ref="refFormSearch">
          <a-row :gutter="16">
            <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
              <a-form-item label="Локация" name="location">
                <!-- :default-object-id="rootLevel?.id" -->
                <object-tree-select v-model="formSearch.objectId"
                                    placeholder="Верхний уровень"/>
              </a-form-item>
            </a-col>
            <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
              <a-form-item label="Тип объекта" name="objectType">
                <a-select v-model:value="formSearch.objectTypeId"
                          show-search :loading="objectTypesLoading"
                          :default-active-first-option="false"
                          :filter-option="false" allow-clear
                          not-found-content="Ничего не найдено"
                          @search="getObjectTypes"
                          placeholder="Выберите тип" size="large">
                  <a-select-option :value="option.id" v-for="(option) in objectTypesData" :key="option.id">
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <template v-if="searchMode === 'reservations'">
              <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                <a-form-item label="Начало" name="date">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-date-picker v-model:value="formSearchDate.dateFrom" placeholder="Дата" size="large"
                                     class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                                     @change="dateChanged('dateFrom')"/>
                    </a-col>
                    <a-col :span="12">
                      <smart-time-field v-model="formSearchDate.timeFrom" v-model:valid="formSearchDate.timeFromValid"
                                        @change="timeChanged('timeFrom')"/>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                <a-form-item label="Окончание" name="date">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-date-picker v-model:value="formSearchDate.dateTo" placeholder="Дата" size="large"
                                     class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"
                                     :disabled-date="disabledDateTo"
                                     @change="dateChanged('dateTo')"/>
                    </a-col>
                    <a-col :span="12">
                      <smart-time-field v-model="formSearchDate.timeTo" v-model:valid="formSearchDate.timeToValid"
                                        @change="timeChanged('timeTo')"/>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
            </template>
            <template v-if="searchMode === 'free_slots'">
              <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                <a-form-item label="Начало" name="date">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-date-picker v-model:value="formSearchDate.freeDateFrom" placeholder="Дата" size="large"
                                     class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
                    </a-col>
                    <a-col :span="12">
                      <smart-time-field v-model="formSearchDate.freeTimeFrom"
                                        v-model:valid="formSearchDate.freeTimeFromValid"/>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :span="6" :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                <a-form-item label="Продолжительность" name="duration">
                  <smart-time-field v-model="formSearchDate.interval"
                                    v-model:valid="formSearchDate.intervalValid" as-duration/>
                </a-form-item>
              </a-col>
            </template>
          </a-row>
          <a-row :gutter="16" type="flex" justify="center" align="bottom">
            <a-col :span="6">
              <a-form-item label="Пользователь">
                <a-select show-search
                          size="large" v-model:value="formSearch.userId" placeholder="Выберите пользователя"
                          :loading="userLoading" allow-clear
                          :default-active-first-option="false"
                          :show-arrow="true"
                          :filter-option="false"
                          :not-found-content="null"
                          @search="fetchUserList"
                >
                  <a-select-option v-for="user in userList" :value="user.id" :disabled="user.disabled"
                                   :key="user.id">{{ user.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-tooltip>
                <template #title>
                  При активном фильтре в&nbsp;выдаче будут отображаться результаты, доступные этому пользователю
                </template>
                <question-circle-outlined class="light-gray-color-text"
                                          :style="{ fontSize: '20px', marginBottom: '26px',
                                                    transition: 'all 0.2s ease-in-out', transform: (searchMode === 'reservations' ? 'scale(0)' : 'none') }"/>
              </a-tooltip>
            </a-col>
            <a-col :span="16" class="text-right">
              <a-form-item>
                <a-space size="middle">
                  <a-button size="large" type="dashed" @click="handleResetFilters">
                    <template #icon>
                      <close-outlined/>
                    </template>
                    Очистить фильтры
                  </a-button>
                  <a-button size="large" v-if="searchMode === 'reservations'"
                            @click="handlerFindReservation">Найти бронирования
                  </a-button>
                  <a-button size="large" v-if="searchMode === 'free_slots'"
                            @click="handlerFindFreeSlots">Найти свободные слоты
                  </a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </nav>

      <div
          style="overflow: auto"
      >
        <a-table style="min-width: 900px;"  :columns="columns" :data-source="data" :pagination="pagination" row-key="id"
               :loading="loading" @change="handleTableChange"
               :row-class-name="(record) => (record.status === 'free' ? 'row-show-select-btn' : '')"
               :locale="{ emptyText: isFilterSelected ? 'Ничего не найдено' : 'Нет данных', filterConfirm: 'Применить', filterReset: 'Сбросить' }"
      >
        <template #time="{ text }">
          <span>{{ moment(text, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY | HH:mm') }}</span>
        </template>
        <template #user="{ record }">
          {{ record.user?.name || '—' }}
        </template>
        <template #objectName="{ record }">
          <a-tooltip :title="renderObjectPath(record.object?.path || [], false) || 'Верхний уровень'">
            <router-link class="text-color-text"
                         :to="{ name: record?.object?.hasChildren ? 'objectChildren' : 'objectView', params: { id: record.object?.id } }">
              {{ record.object?.name || '' }}
            </router-link>
          </a-tooltip>
        </template>
        <template #objectCode="{ record }">
          {{ record.object?.code || '' }}
        </template>
        <template #status="{ text }">
          <span v-if="text === 'reserved'" class="primary-color-text">Забронирован</span>
          <span v-if="text === 'free'" class="primary-light-blue-text">Свободно</span>
          <span v-if="text === 'complete'" class="light-gray-color-text">Завершен</span>
          <span v-if="text === 'in_use'" class="success-color-text">Используется</span>
          <span v-if="text === 'fictive'" class="error-color-text">Не используется</span>
        </template>
        <template #actions="{ record }">
          <a-button key="add" type="primary" size="large" v-if="record.status === 'free'"
                    @click="handleOpenReservationModal(record)">
            Забронировать
          </a-button>
          <a-dropdown :trigger="['click']" v-else>
            <template #overlay>
              <a-menu>
                <a-menu-item key="search" @click="handleSearchFreeSlotsByObject(record)">
                  <search-outlined/>
                  Свободные слоты объекта
                </a-menu-item>
                <a-menu-item key="edit" v-if="record?.status === 'reserved' || record?.status === 'in_use'">
                  <router-link :to="{ name: 'reservationView', params: {id: record.id} }">
                    <edit-outlined/>
                    Редактировать
                  </router-link>
                </a-menu-item>
                <a-menu-item key="view" v-else>
                  <router-link :to="{ name: 'reservationView', params: {id: record.id} }">
                    <eye-outlined/>
                    Просмотр
                  </router-link>
                </a-menu-item>
                <a-menu-item key="move" @click="handleDelete(record.id)">
                  <delete-outlined/>
                  Удалить
                </a-menu-item>
              </a-menu>
            </template>
            <a-button key="more" type="dashed" size="large">
              <more-outlined/>
            </a-button>
          </a-dropdown>

        </template>
      </a-table>
      </div>

      <div v-if="searchMode === 'free_slots' && data?.length >= 100 && !loading" class="disabled-color-text"
           :style="{ padding: '1.5rem 0 2rem', margin: 0, clear: 'both' }">
        Чтобы увидеть больше вариантов бронирования, уточните критерии поиска
      </div>

      <!-- Модалка бронирования -->
      <a-modal v-model:visible="modalAdd.visible" title="Забронировать" width="400px">
        <reservation-preview :name="modalAdd.object?.name" :code="modalAdd.object?.code"
                             :preview-url="modalAdd.object?.previewUrl"/>

        <a-descriptions bordered :column="1" size="small">
          <a-descriptions-item label="Локация">
            {{ renderObjectPath(modalAdd.object?.path || [], false, ', ') || 'Верхний уровень' }}
          </a-descriptions-item>
          <a-descriptions-item label="Дата">
            {{ modalAdd.dateFromDate ? moment(modalAdd.dateFromDate).format('DD.MM.YYYY') : '???' }}
            <span v-if="modalAdd.dateFromDate !== modalAdd.dateToDate">&ndash;
              {{ modalAdd.dateToDate ? moment(modalAdd.dateToDate).format('DD.MM.YYYY') : '???' }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Доступен">
            {{ moment(modalAdd.record.timeFrom, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }} &ndash;
            {{ moment(modalAdd.record.timeTo, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider/>

        <a-form layout="vertical" :model="modalAdd.form" ref="modalAddRef">

          <a-form-item label="Пользователь" name="userId" :rules="[
            { required: true, message: 'Выберите пользователя', trigger: 'blur' },
          ]">
            <a-select show-search
                      size="large" v-model:value="modalAdd.form.userId" placeholder="Выберите пользователя"
                      :loading="userLoading"
                      :default-active-first-option="false"
                      :show-arrow="true"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="fetchUserList"
            >
              <a-select-option v-for="user in userList" :value="user.id" :disabled="user.disabled"
                               :key="user.id">{{ user.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Начало" name="date">
            <a-row :gutter="16">
              <a-col :span="12">
                <a-date-picker v-model:value="modalAdd.dateFromDate" placeholder="Дата" size="large"
                               @change="modalFromDateChanged()"
                               class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
              </a-col>
              <a-col :span="12">
                <smart-time-field v-model="modalAdd.dateFromTime"/>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="Окончание" name="date">
            <a-row :gutter="16">
              <a-col :span="12">
                <a-date-picker v-model:value="modalAdd.dateToDate" placeholder="Дата" size="large"
                               :disabled-date="modalDisabledDateTo"
                               class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
              </a-col>
              <a-col :span="12">
                <smart-time-field v-model="modalAdd.dateToTime"/>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="Участники" v-if="modalAdd.object?.userInvitationAvailable">
            <invite-user-list :list="modalAdd.form.invitedUsers"/>
          </a-form-item>

        </a-form>

        <template #footer>
          <a-button key="submit" size="large" type="primary" class="w-100"
                    @click="handleSetReservationSubmit"
                    :disabled="!modalAdd.form.userId || !modalAdd.dateFromDate || !modalAdd.dateFromTime || !modalAdd.dateToDate || !modalAdd.dateToTime">
            Забронировать
          </a-button>
        </template>
      </a-modal>

    </main>
  </section>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { nextTick, onMounted, unref } from "@vue/runtime-core";
import { urlHelper } from "@/compositions/commonFunctions";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons-vue';
import moment from "moment";
import SearchExpandable from "@/components/common/SearchExpandable";
import { getListData as getReservationData, requestAPI as reservationAPI } from "@/compositions/reservations";
import { getListData as getObjectTypesData } from "@/compositions/objectTypes";
import { getUserListData } from "@/compositions/userAndGroups";
import {
  getListData as getObjectsData,
  getOneData as getOneObject,
  requestAPI as objectAPI
} from "@/compositions/objects";
import ObjectTreeSelect from "@/components/admin/objects/ObjectTreeSelect";
import { Modal, notification } from "ant-design-vue";
import ReservationPreview from "@/components/admin/reservation/ReservationPreview";
import SmartTimeField from "@/components/admin/shared/ul/SmartTimeField";
import InviteUserList from "@/components/admin/reservation/InviteUserList";

export default {
  name: "ReservationListPage",
  setup() {
    const route = useRoute();
    const pageTitle = ref('Бронирования'); // Может меняться, если переходим с экрана объектов

    const {
      loading: objectTypesLoading,
      fetchList: fetchObjectTypes,
      data: objectTypesData,
      search: objectTypesSearch,
    } = getObjectTypesData();

    const {
      loading: objectsLoading,
      fetchList: fetchObjects,
      data: objectsData,
      search: objectsSearch,
      dataMode: objectsSearchMode,
    } = getObjectsData();

    const { renderObjectPath } = getOneObject();
    const { updateReservation, removeReservation } = reservationAPI();
    const { getObjectById } = objectAPI();
    const { loading: userLoading, fetchUserList, data: userList } = getUserListData();

    const modalAddRef = ref(null);
    const modalAdd = reactive({
      object: undefined,
      record: undefined,
      visible: false,
      dateFromDate: undefined,
      dateFromTime: undefined,
      dateToDate: undefined,
      dateToTime: undefined,
      form: {
        objectId: undefined,
        userId: undefined,
        userAccess: true,
        invitedUsers: [],
      },
    });

    // Бронирования
    const {
      DEFAULT_INTERVAL, loading, columns, data, handleTableChange, pagination, isFilterSelected,
      formSearch, formSearchDate, findReservation, resetFilters, searchMode,
      dateChanged, timeChanged, rootLevel, initFilterDefaults,
      disabledDateTo,
    } = getReservationData();
    const refFormSearch = ref(null);

    async function getObjectTypes(search) {
      objectTypesSearch.value = search;
      fetchObjectTypes();
    }

    async function getObjects(search) {
      objectsSearch.value = search;
      fetchObjects();
    }

    onMounted(async () => {
      await initFilterDefaults();
      formSearch.treeSearch = 'all';
      // Загружаем бронирование выбранного объекта
      if (route.params?.objectId) {
        // Get data
        const res = await getObjectById(route.params?.objectId);
        if (res?.id) {
          pageTitle.value = `Бронирование ${res.name}`;
          formSearch.objectId = route.params?.objectId;
          searchMode.value = route.params?.searchMode || 'free_slots';
        }
      }
      // Дополнительно заполняем параметры из предыдущего фильтра
      console.log(route?.params);
      if (route.params?.dateFrom && route.params?.dateFrom !== 'null') {
        formSearchDate.freeDateFrom = route.params.dateFrom;
      }
      if (route.params?.timeFrom) {
        if (route.params?.timeFrom !== 'null') {
          formSearchDate.freeTimeFrom = route.params.timeFrom;
        } else {
          formSearchDate.freeTimeFrom = undefined;
        }
      }
      if (route.params?.interval) {
        formSearchDate.interval = route.params.interval || DEFAULT_INTERVAL;
        if (formSearchDate.interval === '00:00') {
          formSearchDate.interval = '00:01';
        }
      }

      // Загрузить данные для форм
      getObjectTypes();
      getObjects();

      // Подставить данные в выпадающие списки
      await fetchUserList();
      if (route.params?.userId && route.params?.userId !== 'null') {
        formSearch.userId = route.params.userId;
        if (!userList.value.find((option) => option.id === formSearch.userId)) {
          userList.value.unshift({ id: formSearch.userId, name: route.params?.userName || 'Выбранный пользователь' });
        }
      }

      //
      urlHelper.setPageTitle(pageTitle.value);
      await findReservation();
    });

    async function handlerFindReservation() {
      searchMode.value = 'reservations';
      if (formSearch.objectId !== route.params?.objectId) {
        pageTitle.value = `Бронирования`;
      }
      await findReservation();
    }

    async function handlerFindFreeSlots() {
      searchMode.value = 'free_slots';
      if (!formSearch?.objectId) formSearch.objectId = unref(rootLevel)?.id;
      if (formSearch.objectId !== route.params?.objectId) {
        pageTitle.value = `Бронирования`;
      }
      await findReservation();
    }

    async function handleSearchFreeSlotsByObject(record) {
      formSearch.objectId = record?.object?.id;
      objectsData.value = [record?.object];
      const timeFrom = moment(record.timeFrom, 'YYYY-MM-DDTHH:mm:ss');
      const duration = moment.duration(moment(record.timeTo).diff(moment(record.timeFrom)));

      // Загружаем интевал с учетом политики бронирования
      let intervalMinutes = Math.ceil(duration.asMinutes());
      const object = await getObjectById(record?.object?.id, { withReservationPolicy: true });
      if (object?.objectReservationPolicy) {
        intervalMinutes = +object?.objectReservationPolicy?.intervalMinutes;
      }
      if (intervalMinutes <= 0) {
        intervalMinutes = 1;
      }

      var h = intervalMinutes / 60 | 0,
          m = intervalMinutes % 60 | 0;
      // Заполняем дату из слота
      formSearchDate.interval = moment.utc().hours(h).minutes(m).format("HH:mm");
      formSearchDate.freeDateFrom = timeFrom.format('YYYY-MM-DD');
      formSearchDate.freeTimeFrom = undefined;
      await handlerFindFreeSlots();
    }

    async function onSearchModeChange() {
      data.value = [];
      // Обновляем текущую дату при смене таба
      formSearchDate.freeDateFrom = moment().format('YYYY-MM-DD');
      // formSearchDate.freeTimeFrom = moment().format('HH:mm');
      // Проверить, что пользователь не накосячил с формой
      if (!formSearchDate.intervalValid) formSearchDate.interval = DEFAULT_INTERVAL;
      if (!formSearchDate.timeFromValid) formSearchDate.timeFrom = '00:00';
      if (!formSearchDate.timeToValid) formSearchDate.timeTo = '23:59';

      formSearchDate.freeTimeFromValid = formSearchDate.intervalValid = formSearchDate.timeFromValid = formSearchDate.timeToValid = true;
      //  Не выбран объект - выбери родительский
      if (!formSearch?.objectId) formSearch.objectId = unref(rootLevel)?.id;
      await findReservation();
    }

    async function handleDelete(id) {
      Modal.confirm({
        title: 'Удалить бронирование?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        async onOk() {
          await removeReservation(id).then(() => {
            notification.success({
              message: 'Успех',
              description: 'Бронирование успешно удалено',
            });
            findReservation();
          });
        },
        onCancel() {
        },
      });
    }

    function handleOpenReservationModal(record) {
      modalAdd.record = record;
      modalAdd.object = record?.object;

      const timeFrom = moment(record.timeFrom, 'YYYY-MM-DDTHH:mm:00');
      const timeTo = moment(record.timeTo, 'YYYY-MM-DDTHH:mm:00');

      modalAdd.dateFromDate = timeFrom.format('YYYY-MM-DD');
      modalAdd.dateToDate = timeTo.format('YYYY-MM-DD');
      if (formSearchDate.freeTimeFrom) {
        modalAdd.dateFromTime = timeFrom.format('HH:mm');
        modalAdd.dateToTime = timeTo.format('HH:mm');
      } else {
        modalAdd.dateToTime = modalAdd.dateFromTime = '';
      }

      // Данные для отправляемой формы
      modalAdd.form.objectId = record?.object?.id;
      modalAdd.form.userId = formSearch.userId;
      modalAdd.form.userAccess = true;
      modalAdd.form.invitedUsers = [];
      modalAdd.visible = true;
    }

    async function handleSetReservationSubmit() {
      try {
        await modalAddRef.value
            .validate()
            .then(async () => {
              // Это проверяется в том числе на этапе валидации
              if (!modalAdd.dateFromDate || !modalAdd.dateFromTime || !modalAdd.dateToDate || !modalAdd.dateToTime) return;

              const dateFromFull = moment(`${modalAdd.dateFromDate} ${modalAdd.dateFromTime}`, 'YYYY-MM-DD HH:mm');
              const dateToFull = moment(`${modalAdd.dateToDate} ${modalAdd.dateToTime}`, 'YYYY-MM-DD HH:mm');

              if (!dateFromFull.isValid() || !dateToFull.isValid() || dateFromFull >= dateToFull) {
                return notification.error({
                  message: 'Ошибка',
                  description: 'Проверьте, правильно ли выбраны даты',
                });
              }

              const payload = {
                ...modalAdd.form,
                dateFrom: dateFromFull.format('YYYY-MM-DD HH:mm:00'),
                dateTo: dateToFull.format('YYYY-MM-DD HH:mm:00'),
              };

              const res = await updateReservation(payload);
              if (res?.id) {
                notification.success({
                  message: 'Успех',
                  description: `Вы забронировали ${modalAdd.object?.name}`,
                });
                modalAdd.visible = false;
                findReservation();
                // Ошибка бронирования. Вероятно что-то с правами
              } else {
                console.log(res);
                if(res?.errorCode === 'error_update') {
                  Modal.confirm({
                    title: res?.message,
                    okText: 'Да',
                    okType: 'primary',
                    cancelText: 'Нет',
                    async onOk() {
                      payload.userAccess = false;
                      const resTry2 = await updateReservation(payload);
                      if (resTry2?.id) {
                        notification.success({
                          message: 'Успех',
                          description: `Вы забронировали ${modalAdd.object?.name}`,
                        });
                      } else {
                        return notification.error({
                          message: 'Ошибка',
                          description: resTry2?.message || 'Не удалось забронировать',
                        });
                      }
                      modalAdd.visible = false;
                      findReservation();
                    },
                    async onCancel() {
                      modalAdd.visible = false;
                    },
                  });
                } else {
                  return notification.error({
                    message: 'Ошибка',
                    description: res?.message || 'Не удалось забронировать',
                  });
                }
              }
            });

      } catch (e) {
        console.error(e);
      }
    }

    async function handleResetFilters() {
      pageTitle.value = 'Бронирования';
      objectTypesSearch.value = '';
      fetchObjectTypes();
      fetchUserList();
      await resetFilters(true);
    }

    function modalFromDateChanged() {
      if (modalAdd.dateToDate < modalAdd.dateFromDate) {
        nextTick(() => modalAdd.dateToDate = modalAdd.dateFromDate);
      }
    }

    function modalDisabledDateTo(current) {
      return current && current < moment(modalAdd.dateFromDate);
    }

    return {
      moment, pageTitle, loading, isFilterSelected,
      refFormSearch, formSearch, formSearchDate, handleResetFilters,
      dateChanged, timeChanged, modalFromDateChanged,
      columns, data, rootLevel,
      handlerFindReservation, handlerFindFreeSlots, findReservation, handleTableChange, pagination,
      objectTypesData, objectTypesSearch, objectTypesLoading, getObjectTypes,
      objectsData, objectsSearch, objectsLoading, objectsSearchMode, renderObjectPath,
      userLoading, fetchUserList, userList,
      handleDelete, handleSearchFreeSlotsByObject, searchMode,
      modalAdd, modalAddRef, handleOpenReservationModal, handleSetReservationSubmit,
      onSearchModeChange,
      disabledDateTo, modalDisabledDateTo,
    }
  },
  components: {
    InviteUserList,
    SmartTimeField,
    ReservationPreview,
    ObjectTreeSelect,
    SearchExpandable,
    CloseOutlined, DeleteOutlined,
    EditOutlined, SearchOutlined, MoreOutlined, QuestionCircleOutlined, EyeOutlined,
  }
}
</script>

<style lang="less">
.filter-navbar {
  transition: opacity 0.2s ease-in-out;

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }

  .ant-time-picker {
    width: 100%;
  }
}
</style>
